.contact-wrapper {
    padding: 100px 20px;
    background-color: #f19164;
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    margin: 10px 0;
    padding: 10px;
    width: 300px;
    border: none;
    border-radius: 5px;
}

.button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ee5d1a;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
