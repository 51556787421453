/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Noto Serif', serif;
    background-color: #000000;
    color: #f9f9f9;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

/* Additional global styles */
